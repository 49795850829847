import Layout, { LinkDef } from "@/components/Layout";
import { Center, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { useUser } from "@supabase/auth-helpers-react";
import ExportedImage from "next-image-export-optimizer";
import Link from "next/link";
import { FunctionComponent } from "react";

const links: (LinkDef & { icon: string })[] = [
  { name: "OneToOne", href: "/generator", icon: "devices.svg" },
  { name: "Social", href: "/social", icon: "social.svg" },
];

const Home: FunctionComponent = () => {
  const user = useUser();

  const isLoggedIn = !!user;

  return (
    <Layout>
      <Center w="full" h="full">
        {isLoggedIn ? (
          <HStack>
            {links.map(l => (
              <Link href={l.href} key={l.name}>
                <VStack _hover={{ textDecoration: "underline" }}>
                  <Center
                    w={48}
                    h={48}
                    rounded="md"
                    justifyContent="center"
                    alignItems="center"
                    bg="whiteAlpha.500"
                    backdropBlur="md">
                    <ExportedImage
                      src={`/icons/${l.icon}`}
                      width={96}
                      height={96}
                      alt={l.name}
                      /* !TODO: make svg use currentColor (white currently hardcoded) */
                    />
                  </Center>
                  <Heading variant="md">{l.name}</Heading>
                </VStack>
              </Link>
            ))}
          </HStack>
        ) : (
          <Text variant="largeMessage">please sign in first</Text>
        )}
      </Center>
    </Layout>
  );
};

export default Home;
